<template>
  <v-row
    class="d-flex align-center mt-0 pb-4"
    dense
  >
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="row.count_start"
        label="Qtd. Inicial"
        outlined
        rounded
        dense
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="row.count_end"
        label="Qtd. Final"
        outlined
        rounded
        dense
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <v-text-field
        v-model="row.amount"
        v-mask="['#,##', '##,##', '###,##']"
        label="Preço"
        outlined
        rounded
        dense
        hide-details
      />
    </v-col>
    <v-col
      cols="12"
      md="1"
    >
      <v-btn
        v-if="length > 1"
        icon
        @click="$emit('removeRow')"
      >
        <v-icon
          color="error"
          small
          >fas fa-times</v-icon
        >
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    length: Number,
    row: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
